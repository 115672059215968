import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useStore } from "../../app/store/store";
import { Button, Checkbox, Container, Dropdown, DropdownProps, Header, Table } from "semantic-ui-react";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { _GetSessionData, _SetSessionData } from "../../app/lib/CommonFunctions";
import { DrawDateList, MonthlyEWinBox, MyDropDownValue } from "../../app/models/IDayResult";
import agent from "../../app/api/agent";
import EWinningBoxResultDialog from "./eWinningBoxResultDialog";
import DetailEWinningBox from "./DetailEWinningBox";
import MyNewDropDown from "../../app/lib/MyNewDropDown";
import { _DrawTypeOptionsWithAll, _RatingOptions, _StatusOptionsOnly, _WinboxNumberDisplay } from "../../app/lib/CommonData";
import WinningBoxSystem3DDialog from "./WinningBoxSystem3DDialog";
import WinningBoxSystem2DDialog from "./WinningBoxSystem2DDialog";

export default observer(function EWinningBox() {
    const [isLoading, setIsLoading] = useState(false);
    const [isOriginal, setOriginal] = useState(false);
    const [data, setData] = useState<MonthlyEWinBox>();
    const { modalStore, userStore } = useStore();
    const [forecastDate, setForecastDate] = useState<DrawDateList[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [rating, setRating] = useState(parseInt(_GetSessionData("EWinBox_Rating", 6) + ""));
    const [drawType, setDrawType] = useState(_GetSessionData("EWinBox_DrawType", "A"));
    const [directInterval, setDirectInterval] = useState(parseInt(_GetSessionData("EWinBox_DirectInterval", 0) + ""));
    const [systemIntervalOption, setSystemIntervalOptions] = useState<MyDropDownValue[]>([]);
    const [directIntervalOption, setDirectIntervalOptions] = useState<MyDropDownValue[]>([]);
    const [top3IntervalOption, setTop3IntervalOptions] = useState<MyDropDownValue[]>([]);
    const [systemInterval, setSystemInterval] = useState(parseInt(_GetSessionData("EWinBox_SystemInterval", 0)));
    const [top3Interval, setTop3Interval] = useState(parseInt(_GetSessionData("EWinBox_Top3Interval", 0)));
    const [systemStatus, setSystemStatus] = useState<string>(_GetSessionData("EWinBox_SystemStatus", "0"));
    const [top3Status, setTop3Status] = useState<string>(_GetSessionData("EWinBox_Top3Status", "0"));
    const [numberType, setNumberType] = useState<string>(_GetSessionData("EWinBox_NumberType", "0"));

    const numberTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setNumberType(option.value + "");
        _SetSessionData("EWinBox_NumberType", option.value + "");
    }

    const ratingChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        _SetSessionData("EWinBox_Rating", parseInt(option.value + ""));
        setRating(parseInt(option.value + ""));
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setDrawType(option.value + "");
        _SetSessionData("EWinBox_DrawType", option.value + "");
    }

    const directIntervalChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setDirectInterval(parseInt(option.value + ""));
        _SetSessionData("EWinBox_DirectInterval", parseInt(option.value + ""));

    }

    const systemIntervalChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setSystemInterval(parseInt(option.value + ""));
        _SetSessionData("EWinBox_SystemInterval", parseInt(option.value + ""));
    }

    const top3IntervalChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setTop3Interval(parseInt(option.value + ""));
        _SetSessionData("EWinBox_Top3Interval", parseInt(option.value + ""));

    }
    const systemStatusChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setSystemStatus(option.value + "");
        _SetSessionData("EWinBox_SystemStatus", option.value + "");
    }

    const openResultClick = () => {
        localStorage.setItem("SelectedDate", selectedDate);
        localStorage.setItem("ResultType", "Daily");

        const title = "4DGM Daily e-WinBox Result";
        modalStore.openModal(<EWinningBoxResultDialog></EWinningBoxResultDialog>, title, "large", null, () => { })

    };

    const openBoxClick = (box: number) => {
        localStorage.setItem("SelectedBox", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "Direct Numbers for Box: " + (box === 58 ? "[Box 5-8]" : box);
        modalStore.openModal(<DetailEWinningBox></DetailEWinningBox>, title, "tiny", null, () => { })

    };

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        //drawResultStore.setLoadingInitial(true);

        setSelectedDate(option.value + "");

        //drawResultStore.loadDayResult();
        //drawResultStore.setLoadingInitial(false);

    }

    const isOriginalClicked = (event: React.FormEvent<HTMLInputElement>, data: any) => {
        setOriginal(data.checked);
        //_SetSessionData("isOriginal" , data.checked ? "true" : "false");
    }

    const top3StatusChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setTop3Status(option.value + "");
        _SetSessionData("EWinBox_Top3Status", option.value + "");
    }

    const openSystem3DClick = (box: number) => {
        localStorage.setItem("BoxNo", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "System3D Numbers for Box: " + (box === 0 ? "[Box 1-4]" : box);
        modalStore.openModal(<WinningBoxSystem3DDialog></WinningBoxSystem3DDialog>, title, "fullscreen", null, () => { })

    };

    const openSystem2DClick = (box: number) => {
        localStorage.setItem("BoxNo", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "System2D Numbers for Box: " + (box === 0 ? "[Box 1-4]" : box);
        modalStore.openModal(<WinningBoxSystem2DDialog></WinningBoxSystem2DDialog>, title, "fullscreen", null, () => { })

    };

    useEffect(() => {
        var systemType: MyDropDownValue[];
        var top3Type: MyDropDownValue[];
        var directType: MyDropDownValue[];

        systemType = [];
        directType = [];
        top3Type = [];

        systemType.push({
            value: 0,
            text: "All"
        });

        directType.push({
            value: 0,
            text: "All"
        });

        top3Type.push({
            value: 0,
            text: "All"
        });

        agent.Draw4DResult.getInterval(drawType).then((response) => {
            setIsLoading(false);

            response.map((x) => {
                if (x.system_Type === "S") {
                    systemType.push({
                        value: x.id,
                        text: x.data
                    });
                }

                if (x.system_Type === "D") {
                    directType.push({
                        value: x.id,
                        text: x.data
                    });
                }

                if (x.system_Type === "T") {
                    top3Type.push({
                        value: x.id,
                        text: x.data
                    });
                }

                return true;
            });

            setSystemIntervalOptions(systemType);
            setDirectIntervalOptions(directType);
            setTop3IntervalOptions(top3Type);

            setSystemInterval(0);
            setTop3Interval(0);
            setDirectInterval(0);

            _SetSessionData("EWinBox_DirectInterval", 0);
            _SetSessionData("EWinBox_SystemInterval", 0);
            _SetSessionData("EWinBox_Top3Interval", 0);


        });
    }, [drawType]);

    useEffect(() => {
        if (selectedDate && selectedDate.length === 0) return;


        agent.Draw4DResult.getDailyEWinbox(isOriginal, selectedDate).then((response) => {
            setIsLoading(false);
            setData(response);
        });
    }, [isOriginal, selectedDate]);

    useEffect(() => {

        agent.Draw4DResult.getWinningDate("Daily").then((response) => {
            setForecastDate(response);
            if (response.length > 0) {
                setSelectedDate(response[0].draw_Date);
            }
        });
    }, []);



    if (!userStore.isLogged) {
        return (<Header>Not Authorized</Header>)
    }

    if (isLoading) {
        return (<LoadingComponent></LoadingComponent>);
    }
    return (
        <div>
            <Container fluid>
                <Table celled selectable unstackable style={{ "borderCollapse": "collapse", "borderWidth": "4px", 'borderColor': 'red', "width": "48em" }}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <Checkbox label='Show Original?' defaultChecked={isOriginal} onClick={isOriginalClicked} />
                            </Table.Cell>
                            <Table.Cell>Per-Draw Forecast Date:
                                <Dropdown style={{ marginLeft: '10px', zIndex: 99 }} compact
                                    onChange={handleDropDownSelect}
                                    options={forecastDate.map(x => ({ key: x.draw_Date, text: x.draw_Date, value: x.draw_Date }))}
                                    value={selectedDate}

                                />

                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Button size="medium" primary type="button" onClick={() => openResultClick()}>Result</Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled selectable unstackable style={{ "borderCollapse": "collapse", "borderWidth": "4px", 'borderColor': 'red', "width": "48em" }}>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan="6" textAlign="center">
                                <MyNewDropDown onChange={ratingChanged} placeholder="Select Rating" zIndex="25"
                                    toolTip="Select Rating"
                                    options={_RatingOptions} defaultValue={rating}>
                                </MyNewDropDown>
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" zIndex="23"
                                    toolTip="Select Draw Type"
                                    options={_DrawTypeOptionsWithAll} defaultValue={drawType}>
                                </MyNewDropDown>
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={directIntervalChanged} placeholder="Select Direct Interval" zIndex="23"
                                    toolTip="Select Direct Interval"
                                    options={directIntervalOption} defaultValue={directInterval}>
                                </MyNewDropDown>
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="6" textAlign="center">
                                <MyNewDropDown onChange={systemIntervalChanged} placeholder="Select System Interval" zIndex="22"
                                    toolTip="Select System Interval"
                                    options={systemIntervalOption} defaultValue={systemInterval}>
                                </MyNewDropDown>
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={top3IntervalChanged} placeholder="Select Top3 Interval" zIndex="22"
                                    toolTip="Select Top3 Interval"
                                    options={top3IntervalOption} defaultValue={top3Interval}>
                                </MyNewDropDown>
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={systemStatusChanged} placeholder="Select System Status" zIndex="21"
                                    toolTip="Select System Status"
                                    options={_StatusOptionsOnly} defaultValue={systemStatus}>
                                </MyNewDropDown>
                            </Table.Cell>

                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan="6" textAlign="center">
                                <MyNewDropDown onChange={top3StatusChanged} placeholder="Select Top3 Status" zIndex="21"
                                    toolTip="Select Top3 Status"
                                    options={_StatusOptionsOnly} defaultValue={top3Status}>
                                </MyNewDropDown>
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={numberTypeChanged} placeholder="Select Number Type" zIndex="20"
                                    toolTip="Select Number Type"
                                    options={_WinboxNumberDisplay} defaultValue={numberType}>
                                </MyNewDropDown>                        </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(5)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(6)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(7)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(8)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(5)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(6)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(7)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(8)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(5)} size="mini" type="button" primary>Detail Box 5</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(6)} size="mini" type="button" primary>Detail Box 6</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(7)} size="mini" type="button" primary>Detail Box 7</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(8)} size="mini" type="button" primary>Detail Box 8</Button></Header>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openSystem3DClick(5678)} size="mini" type="button" primary color="green">System3D (5-8)</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="8" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(58)} size="mini" type="button" primary>All Boxes (5-8)</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openSystem2DClick(5678)} size="mini" type="button" primary color="green">System2D (5-8)</Button></Header>
                            </Table.Cell>

                        </Table.Row>
                        {data && <Table.Row style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b>{data?.box1_1 ? data?.box1_1.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b>{data?.box1_1 ? data?.box1_1.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b>{data?.box1_1 ? data?.box1_1.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_1 ? data?.box1_1.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_2 ? data?.box1_2.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_2 ? data?.box1_2.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_2 ? data?.box1_2.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_2 ? data?.box1_2.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_3 ? data?.box1_3.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_3 ? data?.box1_3.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_3 ? data?.box1_3.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_3 ? data?.box1_3.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_4 ? data?.box1_4.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_4 ? data?.box1_4.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_4 ? data?.box1_4.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box1_4 ? data?.box1_4.substring(3, 4) : ""}</b>
                            </Table.Cell>
                        </Table.Row>
                        }
                        {data && <Table.Row style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b>{data?.box2_1 ? data?.box2_1.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b>{data?.box2_1 ? data?.box2_1.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_1 ? data?.box2_1.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_1 ? data?.box2_1.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_2 ? data?.box2_2.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_2 ? data?.box2_2.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_2 ? data?.box2_2.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_2 ? data?.box2_2.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_3 ? data?.box2_3.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_3 ? data?.box2_3.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_3 ? data?.box2_3.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_3 ? data?.box2_3.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_4 ? data?.box2_4.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_4 ? data?.box2_4.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_4 ? data?.box2_4.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box2_4 ? data?.box2_4.substring(3, 4) : ""}</b>
                            </Table.Cell>
                        </Table.Row>
                        }
                        {data && <Table.Row style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_1 ? data?.box3_1.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_1 ? data?.box3_1.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_1 ? data?.box3_1.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_1 ? data?.box3_1.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_2 ? data?.box3_2.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_2 ? data?.box3_2.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_2 ? data?.box3_2.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_2 ? data?.box3_2.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_3 ? data?.box3_3.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_3 ? data?.box3_3.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_3 ? data?.box3_3.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_3 ? data?.box3_3.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_4 ? data?.box3_4.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_4 ? data?.box3_4.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_4 ? data?.box3_4.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box3_4 ? data?.box3_4.substring(3, 4) : ""}</b>
                            </Table.Cell>
                        </Table.Row>
                        }
                        {data && <Table.Row style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_1 ? data?.box4_1.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_1 ? data?.box4_1.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_1 ? data?.box4_1.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_1 ? data?.box4_1.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_2 ? data?.box4_2.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_2 ? data?.box4_2.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_2 ? data?.box4_2.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_2 ? data?.box4_2.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_3 ? data?.box4_3.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_3 ? data?.box4_3.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_3 ? data?.box4_3.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_3 ? data?.box4_3.substring(3, 4) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderLeftWidth": "4px", 'borderLeftColor': 'red', "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_4 ? data?.box4_4.substring(0, 1) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_4 ? data?.box4_4.substring(1, 2) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_4 ? data?.box4_4.substring(2, 3) : ""}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ "borderTopWidth": "4px", 'borderTopColor': 'red' }}>
                                <b> {data?.box4_4 ? data?.box4_4.substring(3, 4) : ""}</b>
                            </Table.Cell>
                        </Table.Row>
                        }

                    </Table.Body>
                </Table>
            </Container>
        </div>
    )
})